<template>
  <van-form @submit="onSubmit">
    <van-cell-group title=" " inset>
      <van-field
        v-model="name"
        name="name"
        label="店铺名称"
        placeholder="请输入"
      />
      <van-field name="uploader" label="店铺Logo">
        <template #input>
          <van-uploader
            v-model="fileList"
            :max-count="1"
            :max-size="10 * 1024 * 1024"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field
        v-model="tel"
        name="tel"
        label="客服电话"
        placeholder="请输入"
      />
      <van-field
        v-model="address"
        name="address"
        label="街道地址"
        placeholder="请输入"
      />
      <van-field
        readonly
        clickable
        name="areaCode"
        :model-value="value"
        label="地区选择"
        placeholder="点击选择"
        @click="showArea = true"
      />
      <van-popup v-model:show="showArea" position="bottom">
        <van-area
          :columns-num="3"
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup>
    </van-cell-group>
    <div style="margin: 16px;">
      <van-button round block type="primary" native-type="submit" :loading="btnLoading">
        确定
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'
import Areas from '@/util/area'
import { upload } from '@/util/util'

export default {
  props: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      sellerId: Cookies.get('sellerId'),
      seller: {},
      value: '',
      showArea: false,
      areaList: Areas, // 数据格式见 Area 组件文档
      name: '',
      uploader: [{ url: 'https://img.yzcdn.cn/vant/leaf.jpg' }],
      btnLoading: false,
      areaCode: '',
      fileList: [],
      qiniu: {},
      tel: '',
      address: ''
    })
    const onConfirm = (values) => {
      console.log(values)
      state.areaCode = values[1].code
      state.value = values.map((item) => item.name).join('/')
      state.showArea = false
    }
    const onSubmit = (values) => {
      if (values.uploader.length === 0) {
        Toast('请上传一个LOGO')
        return false
      }
      values.logo = values.uploader[0].url
      delete values.uploader
      state.btnLoading = true
      post('/store.create', {
        sellerId: state.sellerId,
        name: values.name,
        logo: values.logo,
        tel: values.tel,
        address: values.address
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
          useRouter.go(-1)
        } else {
          Toast(res.msg)
          state.btnLoading = false
        }
      })
    }
    const init = () => {
      post('/util.getQiniuToken', {
      }).then(res => {
        state.qiniu = res.data
      })
    }
    // const beforeRead = (file) => {
    //   if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif') {
    //     Toast('图片类型支持: jpg,png,gif')
    //     return false
    //   }
    //   const max = 20 // mb
    //   if (file.size >= max * 1024 * 1024) {
    //     Toast('图片最大 ' + max + 'MB')
    //     return false
    //   }
    //   return true
    // }
    const afterRead = (file) => {
      upload({
        fileBlob: file.file,
        token: state.qiniu.token,
        domain: state.qiniu.domain
      }).then(res => {
        file.url = res.img
        // file.status = ''
        // file.message = ''
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onConfirm,
      onSubmit,
      afterRead
    }
  }
}
</script>

<style scoped>
</style>
